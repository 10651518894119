<template>
  <div class="multiHeaderContainer">
    <!-- <b-card> -->
    <div style="display: flex; justify-content: space-between">
      <div>
        <b-card-title>
          <h3 class="app-title mdsm">{{ this.projectName }}</h3>
        </b-card-title>
      </div>
      <div style="display: flex; justify-content: space-between; gap: 5px;">
        <div style="margin-top: -15px; margin-bottom: 10px;">
          <label>Project:</label
          ><b-form-select
            v-model="selectedAdsOption"
            style="text-transform: capitalize;"
          >
            <template v-for="(option, index) in allOptions">
              <option
                :key="index"
                :value="option === 'Google Ads' ? 'googleAds' : option"
              >
                {{ option === "googleAds" ? "Google Ads" : option }}
              </option>
            </template>
          </b-form-select>
        </div>
      </div>
    </div>
    <div>
      <div class="table-multiheader-container">
        <div class="date-column">
          <table class="table">
            <thead>
              <tr>
                <th></th>
              </tr>
              <tr>
                <th>Projects</th>
                <span></span>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in this.commonProjectNames" :key="index">
                <td class="dateProjectTd padding0">{{ item }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-for="(item, index) in filteredKeys" :key="index">
          <table class="table">
            <thead>
              <tr>
                <th
                  colspan="10"
                  class="mainTh"
                  :style="{
                    backgroundColor: getProjectsBackgroundColor(index),
                  }"
                >
                  <span
                    style="display: flex; justify-content: space-between; align-items: center;"
                  >
                    <span></span>
                    <span style="margin-top: 10px; float: right;"
                      >{{
                        item.key === "googleAds"
                          ? "Google Ads"
                          : item.key === "overall"
                          ? "Overall Insights"
                          : "" || item.key
                      }}
                    </span>
                    <span style="float: right;"
                      ><feather-icon
                        icon="DownloadIcon"
                        color="#200E6B"
                        size="18"
                        style=" cursor: pointer;"
                        @click="
                          exportData(
                            item.dateWiseData,
                            item.key,
                            item.overAllInsights
                          )
                        "
                    /></span>
                  </span>
                </th>
              </tr>
              <tr v-if="businessTypeProject == 'leadgen'">
                <th
                  v-for="(value, key) in projectsMap.projects['leadgen']
                    .googleAds"
                  :key="key"
                >
                  {{ value }}
                </th>
              </tr>

              <tr v-else>
                <th
                  v-for="(value, key) in projectsMap.projects['default']
                    .googleAds"
                  :key="key"
                >
                  {{ value }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="item.key === 'overall'">
                <tr
                  v-for="(i, dataIndex) in item.overAllInsights"
                  :key="dataIndex"
                >
                  <template v-if="businessTypeProject == 'leadgen'">
                    <td
                      v-for="(value, key) in projectsMap.projects[
                        businessTypeProject
                      ].googleAds"
                      :key="key"
                    >
                      {{
                        i &&
                          ((i[0] &&
                            i[0].hasOwnProperty(key) &&
                            removeINR(i[0][key])) ||
                            (i.hasOwnProperty(key) && removeINR(i[key])) ||
                            "0")
                      }}
                    </td>
                  </template>
                  <template v-else>
                    <td
                      v-for="(value, key) in projectsMap.projects['default']
                        .googleAds"
                      :key="key"
                    >
                      {{
                        i &&
                          ((i[0] &&
                            i[0].hasOwnProperty(key) &&
                            removeINR(i[0][key])) ||
                            (i.hasOwnProperty(key) && removeINR(i[key])) ||
                            "0")
                      }}
                    </td>
                  </template>
                </tr>
              </template>
              <template v-if="item.key === 'googleAds'">
                <tr
                  v-for="(i, dataIndex) in item.dateWiseData"
                  :key="dataIndex"
                >
                  <template v-if="businessTypeProject == 'leadgen'">
                    <td
                      v-for="(value, key) in projectsMap.projects['leadgen']
                        .googleAds"
                      :key="key"
                    >
                      {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                    </td>
                  </template>
                  <template v-else>
                    <td
                      v-for="(value, key) in projectsMap.projects['default']
                        .googleAds"
                      :key="key"
                    >
                      {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                    </td>
                  </template>
                </tr>
              </template>
              <template v-if="item.key === 'meta'">
                <tr
                  v-for="(i, dataIndex) in item.dateWiseData"
                  :key="dataIndex"
                >
                  <template v-if="businessTypeProject == 'leadgen'">
                    <td
                      v-for="(value, key) in projectsMap.projects['leadgen']
                        .googleAds"
                      :key="key"
                    >
                      {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                    </td>
                  </template>
                  <template v-else>
                    <td
                      v-for="(value, key) in projectsMap.projects['default']
                        .googleAds"
                      :key="key"
                    >
                      {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- </b-card> -->
  </div>
</template>

<script>
import projectsMap from "@/@core/utils/projectDetailMap";
import { BCard, BCardTitle, BFormSelect } from "bootstrap-vue";
export default {
  data() {
    return {
      projectsMap: projectsMap,
      items: [],
      selectedAdsOption: "overall",
      lightColorHashCodes: [
        "#AFEEEE", // Light Turquoise
        "#E6E6FA", // Light Lavender
        "#F08080", // Light Coral
        "#778899", // Light Slate Gray
        "#9370DB", // Light Indigo
        "#B03060", // Light Maroon
        "#008B8B", // Light Teal
        "#808000", // Light Olive
        "#FFA07A", // Light Salmon
        "#D8BFD8", // Light Orchid
      ],
    };
  },
  watch: {
    filterOption() {
      this.selectedAdsOption = "overall";
    },
  },
  methods: {
    getProjectsBackgroundColor(index) {
      return this.lightColorHashCodes[index % 10] + "!important";
    },
    removeINR(text) {
      text = String(text);
      var pattern = /INR\s*(\d{1,3}(?:,\d{3})*)(?:\.\d+)?/g;
      var result = text.replace(pattern, "$1");
      return result;
    },
    removeEmptyArrays(data) {
      return data.filter(
        (array) =>
          !(
            Array.isArray(array) &&
            array.length === 1 &&
            Object.keys(array[0]).length === 0
          )
      );
    },
    exportData(data, name, dateOverALL) {
      let result = dateOverALL;
      result.forEach((item, index) => {
        if (Array.isArray(item)) {
          item.forEach((obj) => {
            if (index < this.commonProjectNames.length) {
              obj.name = this.commonProjectNames[index];
            }
          });
        } else if (typeof item === "object") {
          if (index < this.commonProjectNames.length) {
            item.name = this.commonProjectNames[index];
          }
        }
      });

      data.forEach((array, index) => {
        if (Array.isArray(array)) {
          array.forEach((obj) => {
            if (index < this.commonProjectNames.length) {
              obj.name = this.commonProjectNames[index];
            }
          });
        }
      });
      const displayArr = {
        ProjectName: "name",
        Spends: "costMicros",
        Impressions: "impressions",
        CPM: "cpm",
        Clicks: "clicks",
        CPC: "cpc",
        CTR: "ctr",
        Conversions: "conversions",
        CPL: "costPerConversion",
        // ProjectName: "name",
        // Impressions: "impressions",
        // Spends: "costMicros",
        // Clicks: "clicks",
        // // Conversions: "conversions",
        // // Revenue: "conversionsValue",
        // // "Conversion Rate": "conversionRate",
        // ROAS: "ROAS",
        // "Avg. CPM": "avg_cpm",
        // "Avg. CPC": "cpc",
      };

      // Check if data is an array and not empty
      if (!Array.isArray(result) || result.length === 0) {
        console.error("Data is empty or not an array");
        return;
      }

      // Function to escape CSV values
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Function to get nested values from objects using a string path
      const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
      };

      // Deep copy of data or dateOverALL based on name
      let newData = [];
      if (name === "overall") {
        newData = JSON.parse(JSON.stringify([].concat(...result)));
      } else {
        newData = JSON.parse(JSON.stringify([].concat(...data)));
      }

      // Create CSV header
      const header = Object.keys(displayArr)
        .map((displayName) =>
          escapeCSV(displayName.charAt(0).toUpperCase() + displayName.slice(1))
        )
        .join(",");

      // Create CSV rows
      const rows = [header].concat(
        newData.map((item) => {
          return Object.keys(displayArr)
            .map((key) => escapeCSV(getNestedValue(item, displayArr[key])))
            .join(",");
        })
      );

      // Combine header and rows to form CSV content
      const csv = rows.join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a download link for the CSV file
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${name}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
  computed: {
    filteredKeys() {
      let res = this.allKeys.filter((key) => key.key == this.selectedAdsOption);
      return res;
    },
    allOptions() {
      const AllTypeKeys = Object.keys(this.tableData || {});
      AllTypeKeys.reverse();
      const keysWithData = AllTypeKeys.map((key) => {
        return {
          key: key,
        };
      });
      const storeNames = keysWithData.map((item) => item.key);
      if (storeNames.length === 1) {
        this.selectedAdsOption = storeNames[0];
      }
      return storeNames;
    },
    allKeys() {
      const AllTypeKeys = Object.keys(this.tableData || {});
      const keysWithData = AllTypeKeys.map((key) => {
        return {
          key: key,
          dateWiseData: this.tableData[key].map((name) => {
            return name.dateWiseData?.map((i) => {
              return i.insights;
            });
          }),
          overAllInsights: this.tableData[key].map((i) => {
            return i.insights;
          }),
        };
      });
      return keysWithData;
    },
    commonProjectNames() {
      const cleanOverALLProjectNames = Object.keys(
        this.tableData.overall || {}
      ).map((key) => this.tableData.overall[key].projectName);
      const cleanMetaProjectNames = Object.keys(this.tableData.meta || {}).map(
        (key) => this.tableData.meta[key].projectName
      );
      const googleAdsProjectNames = Object.keys(
        this.tableData.googleAds || {}
      ).map((key) => this.tableData.googleAds[key].projectName);
      if (this.selectedAdsOption == "overall") {
        return cleanOverALLProjectNames;
      }
      if (this.selectedAdsOption == "googleAds") {
        return googleAdsProjectNames;
      }
      if (this.selectedAdsOption == "meta") {
        return cleanMetaProjectNames;
      }
    },
  },
  components: {
    BCard,
    BCardTitle,
    BFormSelect,
  },
  props: {
    projectName: {
      type: String,
    },
    tableData: {
      type: Object,
    },
    type: {
      type: String,
    },
    businessTypeProject: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.table-multiheader-container {
  display: flex;
  width: 100%;

  overflow-x: auto;

  .table thead th {
    // border-bottom: 1px solid #ebe9f1;
    font-size: 11px !important;
    width: 400px !important;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 0.5px solid #ddd;
    text-align: center;
    font-size: 11px;
    color: black;
    height: 50px;
  }

  .dateTd {
    color: black;
    font-size: 11px !important;
  }

  th {
    color: black;
    background-color: #eff5f5 !important;
  }

  .padding0 {
    padding: 0px;
  }
}

.date-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  color: black;

  .padding0 {
    padding: 0px;
  }

  .paddingMeta0 {
    padding: 0 2px !important;
  }

  td {
    color: black;
    background-color: #ffffff;
  }
}

.dateProjectTd {
  font-size: 11px !important;
  color: black;
}

.dateFacebookProjectTd {
  color: black;
  font-size: 11px !important;
}
</style>
