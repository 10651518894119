var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"multiHeaderContainer"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('b-card-title',[_c('h3',{staticClass:"app-title mdsm"},[_vm._v(_vm._s(this.projectName))])])],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","gap":"5px"}},[_c('div',{staticStyle:{"margin-top":"-15px","margin-bottom":"10px"}},[_c('label',[_vm._v("Project:")]),_c('b-form-select',{staticStyle:{"text-transform":"capitalize"},model:{value:(_vm.selectedAdsOption),callback:function ($$v) {_vm.selectedAdsOption=$$v},expression:"selectedAdsOption"}},[_vm._l((_vm.allOptions),function(option,index){return [_c('option',{key:index,domProps:{"value":option === 'Google Ads' ? 'googleAds' : option}},[_vm._v(" "+_vm._s(option === "googleAds" ? "Google Ads" : option)+" ")])]})],2)],1)])]),_c('div',[_c('div',{staticClass:"table-multiheader-container"},[_c('div',{staticClass:"date-column"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((this.commonProjectNames),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"dateProjectTd padding0"},[_vm._v(_vm._s(item))])])}),0)])]),_vm._l((_vm.filteredKeys),function(item,index){return _c('div',{key:index},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"mainTh",style:({
                  backgroundColor: _vm.getProjectsBackgroundColor(index),
                }),attrs:{"colspan":"10"}},[_c('span',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('span'),_c('span',{staticStyle:{"margin-top":"10px","float":"right"}},[_vm._v(_vm._s(item.key === "googleAds" ? "Google Ads" : item.key === "overall" ? "Overall Insights" : "" || item.key)+" ")]),_c('span',{staticStyle:{"float":"right"}},[_c('feather-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"DownloadIcon","color":"#200E6B","size":"18"},on:{"click":function($event){return _vm.exportData(
                          item.dateWiseData,
                          item.key,
                          item.overAllInsights
                        )}}})],1)])])]),(_vm.businessTypeProject == 'leadgen')?_c('tr',_vm._l((_vm.projectsMap.projects['leadgen']
                  .googleAds),function(value,key){return _c('th',{key:key},[_vm._v(" "+_vm._s(value)+" ")])}),0):_c('tr',_vm._l((_vm.projectsMap.projects['default']
                  .googleAds),function(value,key){return _c('th',{key:key},[_vm._v(" "+_vm._s(value)+" ")])}),0)]),_c('tbody',[(item.key === 'overall')?_vm._l((item.overAllInsights),function(i,dataIndex){return _c('tr',{key:dataIndex},[(_vm.businessTypeProject == 'leadgen')?_vm._l((_vm.projectsMap.projects[
                      _vm.businessTypeProject
                    ].googleAds),function(value,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(i && ((i[0] && i[0].hasOwnProperty(key) && _vm.removeINR(i[0][key])) || (i.hasOwnProperty(key) && _vm.removeINR(i[key])) || "0"))+" ")])}):_vm._l((_vm.projectsMap.projects['default']
                      .googleAds),function(value,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(i && ((i[0] && i[0].hasOwnProperty(key) && _vm.removeINR(i[0][key])) || (i.hasOwnProperty(key) && _vm.removeINR(i[key])) || "0"))+" ")])})],2)}):_vm._e(),(item.key === 'googleAds')?_vm._l((item.dateWiseData),function(i,dataIndex){return _c('tr',{key:dataIndex},[(_vm.businessTypeProject == 'leadgen')?_vm._l((_vm.projectsMap.projects['leadgen']
                      .googleAds),function(value,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(i[0][key] == undefined ? i[0].spend : i[0][key])+" ")])}):_vm._l((_vm.projectsMap.projects['default']
                      .googleAds),function(value,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(i[0][key] == undefined ? i[0].spend : i[0][key])+" ")])})],2)}):_vm._e(),(item.key === 'meta')?_vm._l((item.dateWiseData),function(i,dataIndex){return _c('tr',{key:dataIndex},[(_vm.businessTypeProject == 'leadgen')?_vm._l((_vm.projectsMap.projects['leadgen']
                      .googleAds),function(value,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(i[0][key] == undefined ? i[0].spend : i[0][key])+" ")])}):_vm._l((_vm.projectsMap.projects['default']
                      .googleAds),function(value,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(i[0][key] == undefined ? i[0].spend : i[0][key])+" ")])})],2)}):_vm._e()],2)])])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th')]),_c('tr',[_c('th',[_vm._v("Projects")]),_c('span')])])}]

export { render, staticRenderFns }